<template>
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-start">

              </div>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-end">
                  <div class="row plain-element text-start">
                      <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                        <div class="row plain-element text-start"><h6>Site: </h6>
                          <p>
                          {{ sitePlanData.site_code }} - {{ siteName }}
                          </p>
                        </div>
                      </div>
                      <div v-if="sitePlanData.publish" class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                          <img alt="img" src="https://saperapublicstorage.blob.core.windows.net/strategicplannerstatic/img/icons/published.png" class="img responsive img-icon" style="height: 60px;">
                      </div>
                  </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Site Plan Manager: </h6>
                  <p>
                    {{ sitePlanData.plan_manager }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Created: </h6>
                  <p>
                    {{ sitePlanData.plan_dates[0] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Updated: </h6>
                  <p>
                    {{ sitePlanData.plan_dates[1] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Description: </h6>
                  <p>
                    {{ sitePlanData.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Energy Engineers: </h6>
                  <p style="margin-bottom: 0;" v-for="(engineer, index) in sitePlanData.energy_engineers" :key="index">
                      {{engineer}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-start">
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Total Investment: </h6>
                  <p v-if="sitePlanData.currency_details[1] === '$'">${{Math.round(calculateTotalInvestmentSum(sitePlanData.opportunity_plan_relations) * sitePlanData.currency_details[0]).toLocaleString()}}  </p>
                  <p v-else >{{Math.round(calculateTotalInvestmentSum(sitePlanData.opportunity_plan_relations) * sitePlanData.currency_details[0]).toLocaleString()}}{{sitePlanData.currency_details[1]}} </p>
                </div>
              </div>
            </div>

            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Cost Baseline: </h6>
                  <p>
                    <span v-if="sitePlanData.currency_details[1] === '$'">${{Math.round(sitePlanData.cost_baseline).toLocaleString()}}</span>
                    <span v-else>{{Math.round(sitePlanData.cost_baseline).toLocaleString() }}{{sitePlanData.currency_details[1]}}</span>
                  </p>
                </div>
              </div>
              <div  v-if="sitePlanData.currency !== 'EUR'" class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>EUR{{sitePlanData.currency}} Exchange Rate: </h6>
                  <p>
                    {{ sitePlanData.currency_details[0] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Total Annual CO2 Emission: </h6>
                  <p>
                    {{ Math.round((sitePlanData.co2_emission_tonnes)).toLocaleString()  }} Tonnes
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Annual Electricity CO2 Emission: </h6>
                  <p>
                    {{ Math.round((sitePlanData.co2_electricity_tonnes_previous_year)).toLocaleString()  }} Tonnes
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Renewable Electricity Year: </h6>
                  <p>
                    {{ sitePlanData.co2_electricity_reductions_year  }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Annual Fuel CO2 Emission: </h6>
                  <p>
                    {{ Math.round((sitePlanData.co2_fuel_tonnes_previous_year)).toLocaleString()  }} Tonnes
                  </p>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Renewable Fuels Year: </h6>
                  <p>
                    {{ sitePlanData.co2_fuel_reductions_year  }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="sitePlanData.co2_baseline > 0" class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Additional CO2 Baseline({{ sitePlanData.co2_baseline_year }}): </h6>
                  <p>
                    {{ sitePlanData.co2_baseline }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
              <div v-if="!sitePlanData.publish && sitePlanData.plan_manager ===  getUsername()" class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start">
                  <h6>Additional Options: </h6>
                </div>
                <p class="w-100"></p>
                <div class="row plain-element text-start">
                  <div class=" col col-12 col-sm-12 col-md-10 col-lg-6 text-start" style="margin-top: 5px">
                    <router-link :to="{name: 'plan-graph-create', params: {id: sitePlanData.id}}" class="btn btn-reorder"  style="width: 100%; margin-left: 0;">
                      Add Custom Graph
                    </router-link>
                  </div>
                </div>
                <p class="w-100"></p>
                <div class="row plain-element text-start">
                  <div class=" col col-12 col-sm-12 col-md-10 col-lg-6 text-start" style="margin-top: 5px;">
                    <router-link :to="{name: 'site-plan-delete', params: {id: sitePlanData.id}}" class="btn btn-delete"  style="width: 100%;">
                      Delete Plan
                    </router-link>
                  </div>
                </div>
              </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
            <div v-if="sitePlanData.related_plans.corporate_plans.length > 0" class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Part of Published Corporate Plans: </h6>
                  <p v-for="plan, index in sitePlanData.related_plans.corporate_plans" :key="index">
                    {{ plan[0] }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="sitePlanData.related_plans.division_plans.length > 0" class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start"><h6>Part of Published Division Plans: </h6>
                  <p v-for="plan, index in sitePlanData.related_plans.division_plans" :key="index">
                    {{ plan[0] }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="sitePlanData.related_plans.published_count > 0" class="row plain-element text-start">
                  <p style="font-style: italic;">
                    Unpublishing blocked
                  </p>
            </div>
          </div>
        </div>
</template>

<script>

import { mapGetters } from "vuex";


export default {
  name: "SitePlanDetailsComponent",
  props: {
    
    sitePlanData: {
      type: Object,
      required: true,
    },
    siteName: {
      type: String,
      required: true,
    }
  },
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    ...mapGetters(["getUsername"]),

    calculateTotalInvestmentSum(opportunitiesObject) {
      let totalSum = 0;

      // Iterate through the opportunities object
      for (const opportunityKey in opportunitiesObject) {
        if (Object.prototype.hasOwnProperty.call(opportunitiesObject, opportunityKey)) {
          const opportunity = opportunitiesObject[opportunityKey];
          const investment = opportunity.opportunity__total_investment_eur || 0;

          // Add the investment value to the total sum
          totalSum += investment;
        }
      }

      return totalSum;
    },
  },

  computed: {

  },
  created() {


  },
  beforeRouteUpdate (to, from, next) {

    next();
  }

}
</script>
