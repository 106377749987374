import api from "../../api/api.js";
import axios from "axios";
import { CSRF_TOKEN } from "../../common/csrf_token.js";

const state = {
    energyEyeDashboards: null,
    selectMenus: null,
    areaGraphData: null,
    barGraphData: null,
    distributionGraphData: null,
    statisticalData: null,
    secondaryAxis: false,
    yMax: null,

};


const getters = {
    getSelectMenus: state => state.selectMenus,
    getEnergyEyeDashboards: state => state.energyEyeDashboards,
    getAreaGraphData: state => state.areaGraphData,
    getBarGraphData: state => state.barGraphData,
    getDistributionGraphData: state => state.distributionGraphData,
    getStatisticalData: state => state.statisticalData,
    getSecondaryAxis: state => state.secondaryAxis,
    getYMax: state => state.yMax,
};


const actions = {
  updateEnergyEyeCharts({dispatch}, payload) {
    dispatch ("performSetAreaGraphData", null);
    var new_area_data = {
        labels: payload.area_dataset.labels,
        datasets: payload.area_dataset.datasets,
        ymax: payload.ymax,
        ymin: payload.ymin,
    };
    var new_bar_data = {
        labels: payload.bar_dataset.labels,
        datasets: payload.bar_dataset.datasets,
        ymax: payload.ymax,
        ymin: payload.ymin,
    };
    dispatch ("performSetAreaGraphData", new_area_data);
    dispatch ("performSetBarGraphData", new_bar_data);
  },
  async fetchEnergyEyeDashboards({ commit }) {
    const response = await api.energyEyeDashboards();
    if (!response)  {
      commit("setFormError", "DEMO version. Please reload data");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      commit('setEnergyEyeDashboards', response.queryset);
    }
  },
  async fetchSelectMenus({commit}, payload) {
    commit("setLoader", true);
    const response = await api.energyEyeData(payload);
    if (!response)  {
      commit("setFormError", "DEMO version. Please reload data")
      commit("setLoader", false);
    } else {
      commit("setSelectMenus", response);
      commit("setLoader", false);
    }
  },


  async performExecuteEnergyEyeQuery({commit, dispatch}, payload) {
    commit("setLoader", true);
    commit("setFormError", null);
    commit("setSecondaryAxis", false);
    dispatch ("performSetAreaGraphData", null);
    dispatch ("performSetBarGraphData", null);
    dispatch ("performSetDistributionGraphData", null);
    const response = await api.executeEnergyEyeQuery(payload);
    if (!response)  {
      commit("setFormError", "DEMO version. Press Load Data again")
      commit("setLoader", false);
    } else {
       if (!response.error) {
          commit("setStatisticalData", response.statistical_data);
          var bar_dataset = response.bar_chart_data.meter_labels.map((bar_label, index) => ({
              label: bar_label,
              data: response.bar_chart_data.datasets[index],
              backgroundColor: response.bar_chart_data.colors[index],
              borderColor: response.bar_chart_data.colors[index],
              borderWidth: 1,
              fill: true,
          }));
          var area_dataset = response.area_chart_data.meter_labels.map((area_label, index) => ({
              label: area_label,
              data: response.area_chart_data.datasets[index],
              backgroundColor: response.area_chart_data.colors[index],
              borderColor: response.area_chart_data.colors[index],
              borderWidth: 1,
              fill: true,
            }));
          var distribution_dataset = [
               {
                  label: '% Distribution', // Dataset label
                  data: response.distribution_chart_data.data, // Data values for each section of the doughnut
                  backgroundColor: response.distribution_chart_data.colors, // Colors for each section
                  borderColor: response.distribution_chart_data.colors, // Border colors for each section
                  borderWidth: 2 // Border thickness for the doughnut sections
               }
          ]
          if (response.bar_chart_data.secondary_meter_data.length > 0) {
            var secondary_meter =  {
                  label: response.bar_chart_data.secondary_meter_name,
                  data: response.bar_chart_data.secondary_meter_data, // Sample data for the line
                  type: 'line', // Explicitly set this as a line chart type
                  fill: false, // Don't fill the area under the line

                  borderColor: "black", // Set the line color to black
                  backgroundColor: "black", // Set point color to black
                  borderWidth: 2, // Line thickness
                  pointRadius: 3, // Size of the data points
                  pointHoverRadius: 5, // Size of the points when hovered
                  yAxisID: 'y1',
                  stack: false,
            };
            bar_dataset.unshift(secondary_meter);
            area_dataset.unshift(secondary_meter);
            commit("setSecondaryAxis", true)
            // Remove elements with empty data arrays
            bar_dataset = bar_dataset.filter(dataset => dataset.data && dataset.data.length > 0);
            area_dataset = area_dataset.filter(dataset => dataset.data && dataset.data.length > 0);

          }
          var areaGraphData = {
              labels: response.area_chart_data.labels,
              datasets: area_dataset,
              chartInterval: response.interval,
              chartUnits: response.units_string,
              secondaryChartUnits: response.secondary_units_string
          };
          var barGraphData = {
              labels: response.bar_chart_data.labels,
              datasets: bar_dataset,
              chartInterval: response.interval,
              chartUnits: response.units_string,
              secondaryChartUnits: response.secondary_units_string
          };
          var distributionGraphData = {
              labels: response.distribution_chart_data.labels,
              datasets: distribution_dataset,
              chartUnits: response.units_string,
              secondaryChartUnits: response.secondary_units_string
          };
          dispatch ("performSetAreaGraphData", areaGraphData);
          dispatch ("performSetBarGraphData", barGraphData);
          dispatch ("performSetDistributionGraphData", distributionGraphData);
          commit("setLoader", false);
          commit("setFormError", null);
       } else {
          commit("setLoader", false);
          commit("setFormError", response.error)
       }
    }
  },

  async performDownloadEnergyEyeCsvQuery({ commit }, payload) {
  try {
    commit("setLoader", true);
    commit("setFormError", null);

    // Make GET request to the Django endpoint for CSV download
    const response = await axios.get(`/energy-eye/download-query-csv/${payload}/`, {
      params: payload, // Pass payload as query parameters
      headers: {
        'X-CSRFToken': CSRF_TOKEN,
      },
      responseType: 'blob', // Expect binary data (CSV)
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element for download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Set the download attribute to specify the filename
    link.download = `dataset.csv`;

    // Trigger the download by clicking the link
    link.click();

    commit("setLoader", false);

  } catch (error) {
    console.error('Error generating CSV:', error);
    commit("setLoader", false);
    commit("setFormError", "Submit a ticket with the following error message - " + error.message);

    // Hide the error message after 5 seconds
    setTimeout(() => {
      const errorElement = document.getElementById("formError");
      if (errorElement) {
        errorElement.style.display = "none";
      }
    }, 5000);
  }
},

  async performDownloadEnergyEyeExcelQuery({ commit }, payload) {
      try {
         commit("setLoader", true);
         commit("setFormError", null);

        // Make POST request to the Django endpoint for CSV download
        const response = await axios.get(`/energy-eye/download-query-excel/${payload}/`, {
          headers: {
            'X-CSRFToken': CSRF_TOKEN,
          },
          responseType: 'blob', // Expect binary data (CSV)
        });

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element for download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Set the download attribute to specify the filename
        link.download = `dataset.xlsx`;

        // Trigger the download by clicking the link
        link.click();

        commit("setLoader", false);

      } catch (error) {
        console.error('Error generating Excel:', error);
        commit("setLoader", false);
        commit("setFormError", "Submit a ticket with the following error message - " + error.message);

        // Hide the error message after 5 seconds
        setTimeout(() => {
          const errorElement = document.getElementById("formError");
          if (errorElement) {
            errorElement.style.display = "none";
          }
        }, 5000);
      }
    },

  performSetAreaGraphData({commit}, data) {
    commit("setAreaGraphData", data)
  },
  performSetBarGraphData({commit}, data) {
    commit("setBarGraphData", data)
  },
  performSetDistributionGraphData({commit}, data) {
    commit("setDistributionGraphData", data)
  },
};

const mutations = {
  setEnergyEyeDashboards: (state, energyEyeDashboards) => {
    state.energyEyeDashboards = energyEyeDashboards
  },
  setSelectMenus: (state, selectMenus) => {
    state.selectMenus = selectMenus
  },
  setAreaGraphData: (state, areaGraphData) => {
     state.areaGraphData = areaGraphData
  },
  setBarGraphData: (state, barGraphData) => {
     state.barGraphData = barGraphData
  },
  setDistributionGraphData: (state, distributionGraphData) => {
     state.distributionGraphData = distributionGraphData
  },
  setStatisticalData: (state, statisticalData) => {
     state.statisticalData = statisticalData
  },
  setSecondaryAxis: (state, secondaryAxis) => {
     state.secondaryAxis = secondaryAxis
  },
  setTAxis: (state, secondaryAxis) => {
     state.secondaryAxis = secondaryAxis
  },
  setYMax: (state, yMax) => {
     state.yMax = yMax
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}