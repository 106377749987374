<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>

  <div v-if="getUsername()" class="dashboard-site" style="padding-bottom: 0px;">
    <div v-if="getLoader() == true" class="row">
      <div class="loader" id="loader"></div>
    </div>
    <div class="row row-break"></div>

    <div class="row plain-element">
      <div  class="row plain-element">
        <div v-if="getDashboard()" class="card card-dashboard-details">
          <div class="row plain-element">
            <div class=" col col-12 col-sm-12 order-2 order-sm-2 order-md-2 order-lg-1 col-md-12 col-lg-3 plain-element">
              <div class="row plain-element">
                <div class="card card-events">
                  <div class="row row-company">
                    <div class=" col col-12 col-sm-12 col-md-2 col-lg-4 text-start col-tabs-logo">
                      <img src="https://saperapublicstorage.blob.core.windows.net/media/logos/DEMO.png"
                           class="img responsive">
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-10 col-lg-8 text-start col-company-name text-end">
                      <a class="btn btn-update no-margin">Update Sites</a>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 text-start plain-element col-company-name-mid text-end">
                      <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-4 col-lg-12 plain-element"></div>
                        <div class=" col col-12 col-sm-12 col-md-4 col-lg-12 plain-element">
                          <a href="/user-sites" class="btn btn-update" style="width: 100%; margin-top: 10px;">Update Sites</a>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-4 col-lg-12 plain-element"></div>
                      </div>
                    </div>
                  </div>
                  <div v-if="getDashboard().site_events_dict" class="row plain-element">
                    <div v-if="getDashboard() && getDashboard().site_events_dict[getActiveSite()]" class="row plain-element">
                      <RowEventsComponent
                              :eventsArray=getDashboard().site_events_dict[getActiveSite()]
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div v-if="getDashboard()" class=" col col-12 col-sm-12 order-sm-1 order-md-1 order-lg-2 col-md-12 col-lg-9 plain-element">
              <div v-if="getDashboard().dashboard_sites" class="row plain-element">
                <RowTabsComponent
                  :sitesArray = getDashboard().dashboard_sites
                />
              </div>
              <div class="row plain-element">
                <div v-if="getDashboard().site_actions_count_dict[getActiveSite()] > 0" class="card card-dashboard">
                  <div v-if="getActiveSite() != 'Overall' && getDashboard().site_kpis_dict[getActiveSite()]" class="row plain-element">
                    <div v-if="getDashboard().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict[getActiveSite()].length > 0" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getDashboard().site_kpis_dict[getActiveSite()][0]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getDashboard().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict[getActiveSite()].length > 1" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getDashboard().site_kpis_dict[getActiveSite()][1]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getDashboard().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict[getActiveSite()].length > 2" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getDashboard().site_kpis_dict[getActiveSite()][2]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="getActiveSite() == 'Overall' && getDashboard().site_kpis_dict[getActiveSite()]" class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'Followed KPIs'"
                          :value = getDashboard().site_kpis_dict[getActiveSite()].kpis_watched
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'User Actions'"
                          :value = getDashboard().site_kpis_dict[getActiveSite()].user_actions
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getDashboard().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'User Opportunities'"
                          :value = getDashboard().site_kpis_dict[getActiveSite()].user_opportunities
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <OpenActionsCardComponent
                          :value = getDashboard().site_actions_count_dict[getActiveSite()]
                          :difference = getDashboard().site_open_action_difference_dict[getActiveSite()]
                          :chart = getDashboard().chart_numbers[getDashboard().dashboard_sites.indexOf(getActiveSite())][0]
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <TimeToCloseCardComponent
                          :value = getDashboard().sites_closing_times_dict[getActiveSite()]
                          :difference = getDashboard().difference_closing_times_dict[getActiveSite()]
                          :chart = getDashboard().chart_numbers[getDashboard().dashboard_sites.indexOf(getActiveSite())][1]
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <PotentialSavingsCardComponent
                          :value = getDashboard().site_actions_total_value_dict[getActiveSite()]
                          :difference = getDashboard().site_actions_value_difference_dict[getActiveSite()]
                          :chart = getDashboard().chart_numbers[getDashboard().dashboard_sites.indexOf(getActiveSite())][2]
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <Co2SavingsCardComponent
                          :value = getDashboard().site_total_co2_savings_dict[getActiveSite()]
                          :difference = getDashboard().site_actions_co2_difference_dict[getActiveSite()]
                          :chart = getDashboard().chart_numbers[getDashboard().dashboard_sites.indexOf(getActiveSite())][3]
                        />
                    </div>
                  </div>
                  <div v-if="getDashboard()" class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-8 col-lg-8 plain-element">
                      <div class="row plain-element">
                        <div v-if="getDashboard().sites_latest_actions_dict[getActiveSite()]" class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                            <LatestActionCardComponent
                              :latestActionArray = getDashboard().sites_latest_actions_dict[getActiveSite()]
                            />
                        </div>
                        <div v-else class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                          <div class="card card-site-dashboard card-site-dashboard-3 clickable">
                            <div class="row-card-site-dashboard-title grey">
                              No Data
                            </div>
                          </div>
                        </div>
                        <div v-if="getDashboard().sites_top_opportunities_dict[getActiveSite()]" class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                            <TopOpportunityCardComponent
                              :topOpportunityArray = getDashboard().sites_top_opportunities_dict[getActiveSite()]
                            />
                        </div>
                        <div v-else class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                          <div class="card card-site-dashboard card-site-dashboard-3 clickable">
                            <div class="row-card-site-dashboard-title grey">
                              No Data
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row plain-element">
                        <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                            <CalendarCardComponent/>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                          <SiteInfoCardComponent
                            :sitesDict = getDashboard().site_dict
                          />
                    </div>
                  </div>
                </div>
                <div v-else class="card card-dashboard card-dashboard-empty">
                  <div class="row row-break"></div>
                  <div class="row row-break"></div>
                  <div class="row row-break"></div>

                  <div class="row plain-element text-center">

                    <span class="span-info"> No Actions Data</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
     
    </div>

  </div>
</div>



</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";
import RowEventsComponent from "@/components/dashboards/RowEventsComponent.vue";
import RowTabsComponent from "@/components/dashboards/RowTabsComponent.vue";
import PerformanceKpiCardComponent from "@/components/dashboards/PerformanceKpiCardComponent.vue";
import OverallKpiCardComponent from "@/components/dashboards/OverallKpiCardComponent.vue";
import OpenActionsCardComponent from "@/components/dashboards/OpenActionsCardComponent.vue";
import TimeToCloseCardComponent from "@/components/dashboards/TimeToCloseCardComponent.vue";
import PotentialSavingsCardComponent from "@/components/dashboards/PotentialSavingsCardComponent.vue";
import Co2SavingsCardComponent from "@/components/dashboards/Co2SavingsCardComponent.vue";
import LatestActionCardComponent from "@/components/dashboards/LatestActionCardComponent.vue";
import TopOpportunityCardComponent from "@/components/dashboards/TopOpportunityCardComponent.vue";
import CalendarCardComponent from "@/components/dashboards/CalendarCardComponent.vue";
import SiteInfoCardComponent from "@/components/dashboards/SiteInfoCardComponent.vue";

export default {
  name: "UserDashboard",
  components: {
      NoPermissionComponent,
      RowEventsComponent,
      RowTabsComponent,
      PerformanceKpiCardComponent,
      OverallKpiCardComponent,
      OpenActionsCardComponent,
      TimeToCloseCardComponent,
      PotentialSavingsCardComponent,
      Co2SavingsCardComponent,
      LatestActionCardComponent,
      TopOpportunityCardComponent,
      CalendarCardComponent,
      SiteInfoCardComponent
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getDashboard", "isLoggedIn", "getLoader","getUsername","getActiveSite"]),
    ...mapActions([ "fetchDashboard"]),
    createProfile() {
      this.performSetLoader(true);
      this.performCreateProfile()
    },
    userProfile() {
      this.fetchUserProfile()
    },
  },
  filters: {
  },
  created() {
    document.title = "SAPERA Demo";
    this.fetchDashboard();
    window.scrollTo(0,0);
  },
}
</script>


