<template>
  <div class="card card-site-dashboard card-site-dashboard-1">
    <div class="row-card-site-dashboard-title">
      {{ siteKpiArray.kpi }}
    </div>
    <div v-if="siteKpiArray" class="row-card-site-dashboard-value-1 font-light-green">
      <span v-if="siteKpiArray.value_percentage < -10 && siteKpiArray.reversed == true"
            class="font-light-red">
        {{ siteKpiArray.value_percentage }}%
      </span>
      <span v-else-if="siteKpiArray.value_percentage < -10 && siteKpiArray.reversed == false"
            class="font-light-green">{{ siteKpiArray.value_percentage }}%
       </span>
      <span v-else-if="siteKpiArray.value_percentage > 10 && siteKpiArray.reversed == true"
            class="font-light-red">{{ siteKpiArray.value_percentage }}%
      </span>
      <span v-else-if="siteKpiArray.value_percentage > 10 && siteKpiArray.reversed == false"
            class="font-light-green">{{ siteKpiArray.value_percentage }}%
      </span>
      <span v-else class="font-light-yellow">{{ siteKpiArray.value_percentage }}%
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceKpiCardComponent",
  props: {
    siteKpiArray: { type: Object, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters([]),
    ...mapActions([]),


  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>