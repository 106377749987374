import api from "../../api/api.js";
import router from "../../router.js";

const state = {
  pdfReportList: [],
  pdfReportData: null,
  pdfReportId: "",

};


const getters = {
  getPdfReportList: state => state.pdfReportList,
  getPdfReportData: state => state.pdfReportData,
  getPdfReportId: state => state.pdfReportId,
};


const actions = {
  async fetchAllPdfReports({commit}) {
    commit("setLoader", true);
    const response = await api.getAllPdfReports();

    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setPdfReportList", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
  async performCreatePdfReport({commit}, payload) {
    const response = await api.pdfReportCreate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
      } else {
        router.push(`/`);
      }
    }
  },
  performPdfReportUpdate({commit}, payload) {
    const response = api.pdfReportUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {

        router.push(`/pdf-report-success/${payload.id}`);
      }
  },
  async fetchPdfReportData({commit}, payload) {
    const response = await api.pdfReportData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setPdfReportData", response);
    }
  },
  clearPdfReportData({commit}) {
    commit("setPdfReportData", null);
  }
};

const mutations = {
  setPdfReportList:  (state, pdfReportList) => {
    state.pdfReportList = pdfReportList;
  },
  setPdfReportData: (state, pdfReportData) => {
    state.pdfReportData = pdfReportData;
  },
  setPdfReportId: (state, pdfReportId) => {
    state.pdfReportId = pdfReportId;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}