import api from "../../api/api.js"
import router from "../../router.js"

const state = {

  sitePlans: [],
  hiddenSitePlans: [],
  sitePlanData: null,
  sitePlanCarbonTracker: null,
  nestedSitePlans: null,
  waterfallGraphData: null,
};


const getters = {
  getSitePlans: state => state.sitePlans,
  getHiddenSitePlans: state => state.hiddenSitePlans,
  getSitePlanData: state => state.sitePlanData,
  getSitePlanCarbonTracker: state => state.sitePlanCarbonTracker,
  getNestedSitePlans: state => state.nestedSitePlans,
  getWaterfallGraphData: state => state.waterfallGraphData,
};


const actions = {

  async fetchAllSitePlans({commit}) {
    commit("setLoader", true);
    const response = await api.getAllSitePlans();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setSitePlans", response.queryset);
      commit("setHiddenSitePlans", response.queryset_hidden);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
    async fetchNestedSitePlans({commit}) {
    commit("setLoader", true);
    const response = await api.nestedSitePlans();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setNestedSitePlans", response.queryset);
      commit("setLoader", false);
    }
  },


  async fetchSitePlanData({commit}, payload) {
    const response = await api.sitePlanData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setSitePlanData", response);
      commit("setOpportunityArray", response.additional_details.opportunity_plan_relations);


//      Investment per Project Chart
      var investmentChartDataset = {
                labels: response.additional_details.investment_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.investment_per_project_chart_data[2],
                              data: response.additional_details.investment_per_project_chart_data[1],
                              tooltips: response.additional_details.investment_per_project_chart_data[3],
                              currencies: response.additional_details.investment_per_project_chart_data[4],
                          }]
            };
      commit("setInvestmentChartData", investmentChartDataset);

//            Total investment Waterfall Chart
      var investmentWaterfallChartDataset  =  {
          labels: response.additional_details.total_investment_chart_data[0],
          datasets: [{
                        backgroundColor: response.additional_details.total_investment_chart_data[2],
                        data: response.additional_details.total_investment_chart_data[1],
                        tooltips: response.additional_details.total_investment_chart_data[4],
                        currencies: response.additional_details.total_investment_chart_data[5],
                    }]
      };
      commit("setInvestmentWaterfallChartData", investmentWaterfallChartDataset);

//    Carbon  Climate Action Phase Waterfall
      var carbonClimateActionPhaseWaterfallChartDataset = {
                labels: response.additional_details.carbon_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.additional_details.carbon_climate_action_phase_chart_data[2],
                            data: response.additional_details.carbon_climate_action_phase_chart_data[1],
                            tooltips: response.additional_details.carbon_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setCarbonClimateActionPhaseWaterfallChartData", carbonClimateActionPhaseWaterfallChartDataset);

//    Financial  Climate Action Phase Waterfall
      var financialClimateActionPhaseWaterfallChartDataset = {
                labels: response.additional_details.financial_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.additional_details.financial_climate_action_phase_chart_data[2],
                            data: response.additional_details.financial_climate_action_phase_chart_data[1],
                            tooltips: response.additional_details.financial_climate_action_phase_chart_data[4],
                            currencies: response.additional_details.financial_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setFinancialClimateActionPhaseWaterfallChartData", financialClimateActionPhaseWaterfallChartDataset);


//      Carbon per project chart
      var carbonChartDataset = {
                labels: response.additional_details.carbon_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.carbon_per_project_chart_data[2],
                              data: response.additional_details.carbon_per_project_chart_data[1],
                              tooltips: response.additional_details.carbon_per_project_chart_data[3],
                          }]
            };
      commit("setCarbonChartData", carbonChartDataset);

//      Carbon Waterfall Chart
      var carbonWaterfallChartDataset = {
           labels: response.additional_details.total_carbon_chart_data[0],
           datasets: [{
                      data: response.additional_details.carbon_tracker,
                      borderColor: "#ff7f0f",
                      borderWidth: 4,
                      type: 'line',
                      order: 0,
                      pointRadius: 1,
                    },
                    {
                     backgroundColor: response.additional_details.total_carbon_chart_data[2],
                     data: response.additional_details.total_carbon_chart_data[1],
                     tooltips: response.additional_details.total_carbon_chart_data[5],
                    }],
      };
      commit("setCarbonWaterfallChartData", carbonWaterfallChartDataset);

//      Cost Savings Waterfall Chart
      var costSavingsWaterfallChartDataset = {
           labels: response.additional_details.total_cost_savings_data[0],
           datasets: [{
                         backgroundColor: response.additional_details.total_cost_savings_data[2],
                         data: response.additional_details.total_cost_savings_data[1],
                         tooltips: response.additional_details.total_cost_savings_data[5],
                         currencies: response.additional_details.total_cost_savings_data[6],
                     }],
      };
      commit("setCostSavingsWaterfallChartData", costSavingsWaterfallChartDataset);

//    Cost Savings per Project chart
      var costSavingsChartDataset = {
                labels: response.additional_details.cost_savings_per_project_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.cost_savings_per_project_data[2],
                              data: response.additional_details.cost_savings_per_project_data[1],
                              tooltips: response.additional_details.cost_savings_per_project_data[3],
                              currencies: response.additional_details.cost_savings_per_project_data[4],
                          }]
            };
      commit("setCostSavingsChartData", costSavingsChartDataset);

//      Carbon per Euro chart
      var carbonPerEuroChartDataset = {
                labels: response.additional_details.carbon_per_euro_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.carbon_per_euro_data[2],
                              data: response.additional_details.carbon_per_euro_data[1],
                              tooltips: response.additional_details.carbon_per_euro_data[3],
                              currencies: response.additional_details.carbon_per_euro_data[4],
                          }]
            };
      commit("setCarbonPerEuroChartData", carbonPerEuroChartDataset);

// SITE
//      Carbon per Euro
      if (response.additional_details.carbon_per_euro_data.length > 0) {
        var carbonPerEuroDict = response.additional_details.carbon_per_euro_data[5];
        commit("setCarbonPerEuroDict", carbonPerEuroDict);
      } else {
        commit("setCarbonPerEuroDict", {});
      }
    }
  },

  async performCreateSitePlan({commit}, payload) {
    const response = await api.sitePlanCreate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
      } else {
        router.push(`/site-plan-search`);
      }
    }
  },

};

const mutations = {
  setSitePlans:  (state, sitePlans) => {
    state.sitePlans = sitePlans;
  },
  setNestedSitePlans: (state, nestedSitePlans) => {
    state.nestedSitePlans = nestedSitePlans;
  },
  setHiddenSitePlans:  (state, hiddenSitePlans) => {
    state.hiddenSitePlans = hiddenSitePlans;
  },
  setSitePlanData: (state, sitePlanData) => {
    state.sitePlanData = sitePlanData
  },
  setSitePlanCarbonTracker: (state, sitePlanCarbonTracker) => {
    state.sitePlanCarbonTracker = sitePlanCarbonTracker;
  },
  setWaterfallGraphData: (state, waterfallGraphData) => {
    state.waterfallGraphData = waterfallGraphData;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}