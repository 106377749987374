<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndPdfReportProjects()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'pdf-report-search'}" class="horizontal-link underline-green">PDF Reports
          </router-link>
        </li>
      </ul>
    </div>

    <div class="row plain-element">
      <div class="row row-opportunities">
        <div class="row plain-element">
          <div v-if="getNestedSitesAndPdfReportProjects()" class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site Code</label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearProjects()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getNestedSitesAndPdfReportProjects()" :key="index">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndPdfReportProjects()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Project</label>
              </div>
              <select v-model="project_number" name="project_number" id="project_number" @change="clearSearch()">
                <option value="" selected> All Projects</option>
                <option v-for="(element, index) in getNestedSitesAndPdfReportProjects()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getIsoChapters()" class=" col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">ISO50k1 Chapter</label>
              </div>
              <select v-model="iso_chapter" name="iso_chapter" id="iso_chapter">
                <option value="" selected> All Chapters</option>
                <option v-for="(element, index) in getIsoChapters()" :key="index" :value="element.chapter">
                  <div v-if="element.chapter != '-'" class="row plain-element">
                    {{ element.chapter }} - {{ element.title }}
                  </div>
                  <div v-else class="row plain-element">
                    None
                  </div>
                </option>

              </select>
            </div>
          </div>

          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
            <div v-if="getNestedSitesAndPdfReportProjects()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
        </div>
        <p class="w-100"></p>
        <div v-if="getUsername() && getLoader() == true" class="row">
          <div class="loader" id="loader"></div>
        </div>
        <div v-if="getPdfReportList()  && getLoader() == false" class="row plain-element">
          <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
            <h6> {{ isoFilteredList.length }} Results</h6>
          </div>
        </div>
        <p class="w-100"></p>
        <div id="parent">
          <div id="child">
            <table v-if="getPdfReportList() && getLoader() == false" name="text" id="searchTable">
              <thead v-if="isoFilteredList.length < 100 && isoFilteredList.length > 0">
              <tr>
                <th @click="sort(0)" class="text-start">Site Code
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(1)" class="text-start">Project No.
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(2)" class="text-start">Title
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(3)" class="text-center">ISO50k1
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(4)" class="text-center">Uploaded
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(5)" class="text-center">User
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sort(6)" class="text-center">Download
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
              </tr>
              </thead>
              <thead v-else-if="isoFilteredList.length > 100">
              <tr>
                <th class="text-start">Site Code
                </th>
                <th class="text-start">Project No.
                </th>
                <th class="text-start">Title
                </th>
                <th class="text-center">ISO50k1
                </th>
                <th class="text-center">Uploaded
                </th>
                <th class="text-center">User
                </th>
                <th class="text-center">Download
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="report in isoFilteredList" :key="report.id">
                <td class="text-start field-number" @click="goToPdfReportUpdateView(report.id.toString())">{{report.site_code__site_code}}</td>
                <td class="text-start field-number" @click="goToPdfReportUpdateView(report.id.toString())">{{report.project_number}}</td>
                <td class="text-start field-description" @click="goToPdfReportUpdateView(report.id.toString())">{{report.filename | truncatechars(80) |
                  normalize() }}
                </td>
                <td class="text-center field-number" @click="goToPdfReportUpdateView(report.id.toString())">{{report.iso_chapter__chapter }}</td>
                <td class="text-center field-number" @click="goToPdfReportUpdateView(report.id.toString())">{{report.date.slice(0,10)}}</td>
                <td class="text-center field-number" @click="goToPdfReportUpdateView(report.id.toString())">{{report.user}}</td>
                <td class="text-center field-number">
                  <a class="btn btn-report" style="color: #fff !important" :href="'/api/pdf-report-download/' + report.filename">
                    <i class="fas fa-file-download"></i>&nbsp; Download
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { sortTable, sortTableInt } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "PdfReportStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "opportunities",
      siteList: null,
      searchQuery: null,
      site: "",
      project_number: "",
      iso_chapter: "",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getNestedSitesAndPdfReportProjects", "isLoggedIn", "getLoader", "getResultCount", "getPdfReportList", "getIsoChapters" ]),
    ...mapActions([ "performSetFormError", "fetchAllPdfReports", "fetchNestedSitesAndPdfReportProjects", "performSetFormError", "fetchIsoChapters", "clearPdfReportData"]),
    getFullPdfReportList() {
      this.fetchAllPdfReports();
    },
    hasHistory () {
      return window.history?.length > 1
    },
    clearProjects() {
        if (this.site == "") {
          this.project_number = ""
          this.status = ""
          this.search = ""
          this.iso_chapter = ""
        } else {
          this.project_number = ""
          this.status = ""
          this.search = ""
          this.iso_chapter = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
//    TABLE_SORTING FUNCTIONS
    sort(n) {
      sortTable(n)
    },
    sortInt(n) {
      sortTableInt(n)
    },
    goToPdfReportUpdateView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'pdf-report-update', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'pdf-report-update', params: { id: id } });
//        let routeData = this.$router.resolve({ name: 'pdf-report-update', params: { id: id } });
//        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {
    filteredList() {
        if (this.project_number != ""  && this.site != "") {
            return this.getPdfReportList().filter(report => {
              return report.project_number.toLowerCase().includes(this.project_number.toLowerCase())
            })
        } else if (this.site != ""  && this.project_number == "") {
            return this.getPdfReportList().filter(report => {
              return report.site_code__site_code.toLowerCase() == this.site.toLowerCase()
            })
        } else {
          return this.getPdfReportList()
        }
    },
    keywordFilteredList() {
      return this.filteredList.filter(report => {
        return report.site_code__site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               report.project_number.toLowerCase().includes(this.search.toLowerCase()) ||
               report.filename.toLowerCase().includes(this.search.toLowerCase()) ||
               report.iso_chapter__chapter.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    isoFilteredList() {
      return this.keywordFilteredList.filter(report => {
        if (this.iso_chapter == "None") {
          return report.iso_chapter__chapter === null
        } else if (report.iso_chapter__chapter && this.iso_chapter != "" && this.iso_chapter != "None") {
          return report.iso_chapter__chapter.toLowerCase() == this.iso_chapter.toLowerCase()
        } else if (report.iso_chapter__chapter && this.iso_chapter == "") {
          return report.project_number.toLowerCase().includes("")
        }


      })
    }
  },
  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
        }
        return value
      }
    },
    normalize(value) {
      if (value) {
        value = value.split('_').splice(3, value.length).join(" ").replaceAll(".pdf", "")
//        value = value.replaceAll("_", " ").replaceAll(".pdf", "")
      }
      return value
    }
  },
  created() {
    this.fetchNestedSitesAndPdfReportProjects();
    this.fetchIsoChapters();
    this.performSetFormError(null);
    this.getFullPdfReportList();
    this.clearPdfReportData();
    document.title = "Search PDF Reports";
    window.scrollTo(0,0);
  },

}
</script>

