import api from "../../api/api.js"

const state = {
  powerBIDashboards: null,
  singleSite: false,
};


const getters = {
  getPowerBIDashboards: state => state.powerBIDashboards,
  getSingleSite: state => state.singleSite
};


const actions = {
  async fetchPowerBIDashboards({ commit }) {
    const response = await api.powerBIDashboards();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      const keys = Object.keys(response.queryset);
      if (keys.length === 1) {
        commit("setSingleSite", true);
      } else {
        commit("setSingleSite", false);
      }
      commit('setPowerBIDashboards', response.queryset);
    }
  },
};

const mutations = {
  setPowerBIDashboards: (state, powerBIDashboards) => {
    state.powerBIDashboards = powerBIDashboards
  },
  setSingleSite(state, singleSite) {
    state.singleSite = singleSite;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}