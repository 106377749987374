import { apiService } from "@/common/api.service.js";

export default {
// Get current user information
  userInfo() {
    let endpoint = "/auth/current-user/";
    return apiService(endpoint);
  },
// Get company information
  companyInfo() {
    let endpoint = "/company";
    return apiService(endpoint);
  },

// Get all opportunities
  getAllOpportunities() {
    let endpoint = `/api/opportunity-all/`;
    return apiService(endpoint);
  },
// Search opportunity query
  searchOpportunityQuery(searchQuery) {
    let endpoint = `/api/opportunities/?search=${searchQuery}`;
    return apiService(endpoint);
  },
// Get Site opportunities
  siteOpportunities(site) {
    let endpoint = `/api/opportunities/?site_code__id=${site}`;
    return apiService(endpoint);
  },
// Get Seu opportunities
  seuOpportunities(seu) {
    let endpoint = `/api/opportunities/?seu__id=${seu}`;
    return apiService(endpoint);
  },
// Get opportunity details
  opportunityData(opportunityId) {
    let endpoint = `/api/opportunities/${opportunityId}/`;
    return apiService(endpoint);
  },
// Get status details
  opportunityStatusDetails(opportunityId) {
    let endpoint = `/api/opportunity-status-details/${opportunityId}/`;
    return apiService(endpoint);
  },
// Change status details
  changeOpportunityStatusDetails(payload) {
    let endpoint = `/api/opportunity-status-details/${payload.opportunity_id}/`;
    return apiService(endpoint, "POST", payload);
  },
// Create Opportunity
  opportunityCreate(payload) {
    let endpoint = `/api/opportunity-create/`;
    return apiService(endpoint, "POST", payload)
  },
// Update Opportunity
  opportunityUpdate(payload) {
    let endpoint = `/api/opportunity-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },
// Change Opportunity Status
  changeOpportunityStatus(payload) {
    let endpoint = `/api/opportunity-status/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Create Opportunity Log Entry
  opportunityLogEntryCreate(payload) {
    let endpoint = `/api/opportunity-logs/`;
    return apiService(endpoint, "POST", payload);
  },
// Create Predecessor relation
  createPredecessorRelation(payload) {
    let endpoint = `/api/predecessor-relation/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Create Conflict relation
  createConflictRelation(payload) {
    let endpoint = `/api/conflict-relation/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Sites and Seu
  sitesAndSeu() {
    let endpoint = `/api/sites-and-seu/`;
    return apiService(endpoint);
  },
  sitesAndSeuAndOpp() {
    let endpoint = `/api/sites-and-seu-and-opp/`;
    return apiService(endpoint);
  },
// Sites present in opportunities queryset
  opportunitiesSites() {
    let endpoint = `/api/opportunities-sites/`;
    return apiService(endpoint);
  },
// Site View
  siteView(id) {
    let endpoint = `/api/site/${id}/`;
    return apiService(endpoint);
  },

// Helper View to get Site specific seu for navigation
  siteSeuList(id) {
    let endpoint = `/api/site-seu/${id}/`;
    return apiService(endpoint);
  },
// Seu View
  siteSeuView(site_id, seu_id) {
    let endpoint = `/api/seu/${site_id}/${seu_id}/`;
    return apiService(endpoint);
  },
// Nested Site Seu for filters
  nestedSitesAndSeu() {
    let endpoint = `/api/nested-site-seu/`;
    return apiService(endpoint);
  },
// Get Opportunity Number
  opportunityNumber(id) {
    let endpoint = `/api/get-opportunity-number/${id}/`
    return apiService(endpoint);
  },


// Get all actions
  getAllActions() {
    let endpoint = `/api/action-all/`;
    return apiService(endpoint);
  },
// Search action query
  searchActionQuery(searchQuery) {
    let endpoint = `/api/actions/?search=${searchQuery}`;
    return apiService(endpoint);
  },
// Get action details
  actionData(actionId) {
    let endpoint = `/api/actions/${actionId}/`;
    return apiService(endpoint);
  },
// Create Action
  actionCreate(payload) {
    let endpoint = `/api/action-create/`;
    return apiService(endpoint, "POST", payload)
  },
// Update Action
  actionUpdate(payload) {
    let endpoint = `/api/action-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },
// Change Action Status
  changeActionStatus(payload) {
    let endpoint = `/api/action-status/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Change Action Graph Type
  changeActionGraphType(payload) {
    let endpoint = `/api/action-graph/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Create Action Log Entry
  actionLogEntryCreate(payload) {
    let endpoint = `/api/action-logs/`;
    return apiService(endpoint, "POST", payload);
  },
// Generate Latex Report for download
  generateReport() {
    let endpoint =`/latex-reports/generate-report/`;
    return apiService(endpoint);
  },

// Action Types
  actionTypes() {
    let endpoint = `/api/action-types/`;
    return apiService(endpoint);
  },
// Event Types
  eventTypes() {
    let endpoint = `/api/event-types/`;
    return apiService(endpoint);
  },
// Cost Intervals
  costIntervals() {
    let endpoint = `/api/cost-intervals/`;
    return apiService(endpoint);
  },
// Opportunity Status
  opportunityStatusList() {
    let endpoint = `/api/opportunity-status-list/`;
    return apiService(endpoint);
  },
// Create Enpi-Action relation
  createEnpiActionRelation(payload) {
    let endpoint = `/api/enpi-action/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Create Opportunity-Action relation
createOpportunityActionRelation(payload) {
    let endpoint = `/api/opportunity-action/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },

// NestedEnpi

  nestedEnpi() {
    let endpoint = `/api/nested-enpi/`;
    return apiService(endpoint);
  },
  siteSeuEnpi(payload) {
    let endpoint = `/api/enpi/?site_code__id=${payload.site_id}&seu__id=${payload.seu_id}`;
    return apiService(endpoint);
  },
  siteEnpi(payload) {
    let endpoint = `/api/enpi/?site_code__id=${payload.site_id}`;
    return apiService(endpoint);
  },
  enpiCreate(payload) {
    let endpoint = `/api/enpi-create/`;
    return apiService(endpoint, "POST", payload)
  },

// Power Bi
  SEUDashboard(payload) {
    let endpoint = `/power-bi/seu-dashboard/`;
    return apiService(endpoint, "POST", payload);
  },
  powerBIDashboards() {
      let endpoint = `/api/nested-site-dashboards/`;
      return apiService(endpoint);
  },
  energyEyeDashboards() {
      let endpoint = `/api/nested-energyeye-dashboards/`;
      return apiService(endpoint);
  },

// Get all sites
  getAllSites() {
    let endpoint = `/api/sites/`;
    return apiService(endpoint);
  },

  getAllIsoChapters() {
      let endpoint = `/api/iso-chapters/`;
      return apiService(endpoint);
  },

  nestedSitesAndPdfReportProjects() {
    let endpoint = `/api/nested-site-pdf-report-projects/`;
    return apiService(endpoint);
  },



//
// METRICS
//

  eeosRates(payload) {
    let endpoint = `/metrics/eeos-rate/?site_code__site_code=${payload.site}`;
    return apiService(endpoint);
  },


//
// PDF REPORTS
//

  getAllPdfReports() {
    let endpoint = `/api/pdf-report-all/`;
    return apiService(endpoint);
  },


//
// CAPACITY TOOL
//

// Nested Rated Capacities for Filtering
  nestedRatedCapacities() {
    let endpoint = `/api/nested-rated-capacities/`;
    return apiService(endpoint);
  },
  getCapacity(payload) {
    let endpoint = `/api/get-capacity/${payload.site_code}/${payload.meter_tag}/`;
    return apiService(endpoint)
  },


//
// EM3 STRATEGIC PLANNER
//
// Get plan details
  sitePlanData() {
    let endpoint = `/api/plan/`;
    return apiService(endpoint);
  },


//
// HOME DASHBOARD
//


//  Site Dashboards
  dashboard() {
    let endpoint = `/api/dashboard/`;
    return apiService(endpoint);
  },


//  Pagination
  nextPage(next) {
    let endpoint = next;
    return apiService(endpoint);
  }
}