import api from "../../api/api.js";
import router from "../../router.js";

const state = {
  actions: [],
  actionData: null,
  actionId: "",
  enpiArray: null,
  opportunityArray: null,

};


const getters = {
  getActions: state => state.actions,
  getActionData: state => state.actionData,
  getActionId: state => state.actionId,
  getEnpiArray: state => state.enpiArray,
  getOpportunityArray: state => state.opportunityArray,
};


const actions = {
  async fetchAllActions({commit}) {
    commit("setLoader", true);
    const response = await api.getAllActions();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setActions", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
  async fetchActionData({commit}, payload) {
    const response = await api.actionData(payload.id);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setActionData", response);
      commit("setEnpiArray", response.enpi_action_relations);
      commit("setOpportunityArray", response.opportunity_action_relations);
    }
  },
  async performActionCreate({commit}, payload) {
    const response = await api.actionCreate(payload);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
//    Switch it for django view
//      router.push(`/action/${response.id}`);
//      router.push(`/action-details/${response.id}`);
      router.push(`/action-success/${response.id}`);
    }
  },
  performActionUpdate({commit}, payload) {
    const response = api.actionUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
      router.push(`/action-success/${payload.id}`);
    }

  },
  async performActionLogEntryCreate({commit}, payload) {
    const response = await api.actionLogEntryCreate(payload);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
        commit("setLoader", false);
    }
  },
  async performCreateEnpiActionRelation({commit}, payload) {
    const response = await api.createEnpiActionRelation(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/action-success/${payload.id}`);
    }
  },
  async performCreateOpportunityActionRelation({commit}, payload) {
    const response = await api.createOpportunityActionRelation(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/action-success/${payload.id}`);
    }
  },
  async performChangeActionStatus({commit}, payload) {
    const response = await api.changeActionStatus(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/action-success/${payload.id}`);
    }
  },
  async performChangeActionGraphType({commit}, payload) {
    const response = await api.changeActionGraphType(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/action-success/${payload.id}`);
    }
  },

//  LATEX REPORT GENERATOR
  async performGenerateReport({commit}) {
    const response = await api.generateReport()
    if (!response) {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        console.log("xxx")
    }
  },

};


const mutations = {
  setActions:  (state, actions) => {
    state.actions = actions;
  },
  setActionData: (state, actionData) => {
    state.actionData = actionData;
  },
  setActionId: (state, actionId) => {
    state.actionId = actionId;
  },
  setEnpiArray: (state, enpiArray) => {
    state.enpiArray = enpiArray;
  },
  setOpportunityArray: (state, opportunityArray) => {
    state.opportunityArray = opportunityArray;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}