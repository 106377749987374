<template>
  <div v-if="value && difference < 0"

       class="card card-site-dashboard card-site-dashboard-2 clickable"
       :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-desc-negative-' + chart + '.png' +  ')' }"
  >
    <div class="row-card-site-dashboard-title">
      CO2 Savings<span style="font-size: 14px; position: relative; top: -0.5px;">(T)</span>
    </div>
    <div class="row-card-site-dashboard-value-2">
      <i-count-up
              :start="0"
              :endVal="value"
              :options="options"
      ></i-count-up>
    </div>
    <div v-if="difference < 0"
         class="row-card-site-dashboard-change appear red">
      <i class="fa-solid fa-caret-down"></i>{{difference}}% m/m
    </div>
    <div v-else-if="difference == 0"
         class="row-card-site-dashboard-change appear grey">
      {{difference}}% m/m
    </div>
    <div v-else class="row-card-site-dashboard-change appear green">
      <i class="fa-solid fa-caret-up"></i>{{difference}}% m/m
    </div>
  </div>
  <div v-else-if="value && difference == 0"

       class="card card-site-dashboard card-site-dashboard-2 clickable"
       :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-neutral-' + chart + '.png' +  ')' }"
  >
    <div class="row-card-site-dashboard-title">
      CO2 Savings<span style="font-size: 14px; position: relative; top: -0.5px;">(T)</span>
    </div>
    <div class="row-card-site-dashboard-value-2">
      <i-count-up
              :start="0"
              :endVal="value"
              :options="options"
      ></i-count-up>
    </div>
    <div v-if="difference < 0"
         class="row-card-site-dashboard-change appear red">
      <i class="fa-solid fa-caret-down"></i>{{difference}}% m/m
    </div>
    <div v-else-if="difference == 0 "
         class="row-card-site-dashboard-change appear grey">
      {{difference}}% m/m
    </div>
    <div v-else class="row-card-site-dashboard-change appear green">
      <i class="fa-solid fa-caret-up"></i>{{difference}}% m/m
    </div>
  </div>
  <div v-else-if="value && difference > 0"

       class="card card-site-dashboard card-site-dashboard-2 clickable"
       :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-asc-positive-' + chart + '.png' +  ')' }"
  >
    <div class="row-card-site-dashboard-title">
      CO2 Savings<span style="font-size: 14px; position: relative; top: -0.5px;">(T)</span>
    </div>
    <div class="row-card-site-dashboard-value-2">
      <i-count-up
              :start="0"
              :endVal="value"
              :options="options"
      ></i-count-up>
    </div>
    <div v-if="difference < 0"
         class="row-card-site-dashboard-change appear red">
      <i class="fa-solid fa-caret-down"></i>{{difference}}% m/m
    </div>
    <div v-else-if="difference == 0"
         class="row-card-site-dashboard-change appear grey">
      {{difference}}% m/m
    </div>
    <div v-else class="row-card-site-dashboard-change appear green">
      <i class="fa-solid fa-caret-up"></i>{{difference}}% m/m
    </div>
  </div>
  <div v-else class="card card-site-dashboard">
    <div class="row-card-site-dashboard-title grey">
      No Data
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ICountUp from 'vue-countup-v2';

export default {
  name: "Co2SavingsCardComponent",
  components: {
      ICountUp,
  },
  props: {
    value: {type: Number, required: true },
    difference: {type: Number, required: true },
    chart: {type: Number, required: true },
  },
  data() {
    return {
      options: {
            useEasing: true,
            useGrouping: true,
            duration: 1,
      },
    }
  },
  methods: {
    ...mapGetters([]),
    ...mapActions([]),
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },
    goToActions() {
        window.open(`/action-search`, '_blank');
    },

  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>