import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import MenuView from "./views/Menu.vue";
import OpportunityStandardSearch from "./views/opportunities/OpportunityStandardSearch.vue";
import OpportunityView from "./views/opportunities/OpportunityView.vue";

import ActionStandardSearch from "./views/actions/ActionStandardSearch.vue";
import ActionView from "./views/actions/ActionView.vue";

import PdfReportStandardSearch from "./views/pdf_reports/PdfReportStandardSearch.vue";

import SEUDashboards from "./views/dashboards/SEUDashboards.vue";

import SiteReports from "./views/sites/SiteReports.vue";

import CapacityTool from "./views/capacity_tool/CapacityTool.vue";

import SitePlanView from "./views/plans/site_plans/SitePlanView.vue";

import PowerBISelectView from "./views/power_bi/PowerBISelectView.vue";
import EnergyEyeView from "./views/energy_eye/EnergyEyeView.vue";
import EnergyEyeSelectView from "./views/energy_eye/EnergyEyeSelectView.vue";

import NotFound from "./views/NotFound.vue";


Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/opportunity-search",
      name: "opportunity-search",
      component: OpportunityStandardSearch
    },
    {
      path: "/opportunity/:id",
      name: "opportunity-view",
      component: OpportunityView,
      props: true
    },
    {
      path: "/site-reports",
      name: "site-reports",
      component: SiteReports
    },
    {
      path: "/action-search",
      name: "action-search",
      component: ActionStandardSearch
    },
    {
      path: "/action/:id",
      name: "action-view",
      component: ActionView,
      props: true
    },
    {
      path: "/seu-dashboards/",
      name: "seu-dashboards",
      component: SEUDashboards,
    },
    {
      path: "/search/pdf-reports",
      name: "pdf-reports-search",
      component: PdfReportStandardSearch,
    },
    {
      path: "/capacity-tool",
      name: "capacity-tool",
      component: CapacityTool,
    },
    {
      path: "/site-plan/:tab/:id",
      name: "site-plan-view",
      component: SitePlanView,
      props: true
    },
    {
      path: "/menu",
      name: "menu",
      component: MenuView,
    },
    {
      path: "/powerbi-select/:site",
      name: "powerbi-select",
      component: PowerBISelectView,
      props: true
    },
    {
      path: "/energyeye-select",
      name: "energyeye-select",
      component: EnergyEyeSelectView,
      props: true
    },
    {
      path: "/energy-eye/:site",
      name: "energy-eye",
      component: EnergyEyeView,
      props: true
    },

    {
      path: "*",
      name: "page-not-found",
      component: NotFound,
    },
  ]
})
