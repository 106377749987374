<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndSeu()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'opportunity-search'}" class="horizontal-link underline-green">Opportunity Search
          </router-link>
        </li>
      </ul>
    </div>

    <div class="row plain-element">
      <div class="row row-opportunities">
        <div class="row plain-element">
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site Code</label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearSeu()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getNestedSitesAndSeu()" :key="index">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">SEU</label>
              </div>
              <select v-model="seu" name="seu" id="seu" @change="clearSearch()">
                <option value="" selected> All SEU</option>
                <option v-for="(element, index) in getNestedSitesAndSeu()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-1 col-lg-2 plain-element text-end">
          </div>
          <div class=" col col-12 col-sm-12 col-md-5 col-lg-4 plain-element text-end">
            <div v-if="getNestedSitesAndSeu()" class="input-field  col col-12 col-sm-12 col-form">

              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
        </div>
        <div v-if="getLoader() == true" class="row">
            <div class="loader" id="loader"></div>
        </div>
        <div v-if="getOpportunities()  && getLoader() == false" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element">
            <div class="card insights-small left insights-small-left">
              <table>
                <td>Total Cost Savings:</td>
                <td v-if="getSavingsSum()"><b class="font-green">€
                  <i-count-up
                          :start="0"
                          :endVal="getSavingsSum()"
                          :duration="0.5"
                          :callback="callback"
                  ></i-count-up>
                </b></td>
                <td v-else style="text-align: left"><b class="font-green">€ 0

                </b></td>
              </table>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element">
            <div class="card insights-small">
              <table>
                <td>Total CO2 Savings:</td>
                <td v-if="getCo2TonnesSum()"><b class="font-blue">
                  <i-count-up
                          :start="0"
                          :endVal="getCo2TonnesSum()"
                          :duration="0.5"
                          :callback="callback"
                  ></i-count-up>
                  T
                </b></td>
                <td v-else style="text-align: left"><b class="font-blue">€ 0 </b></td>
              </table>
            </div>
          </div>

          <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element">
            <div class="card insights-small insights-small-right right">
              <table>
                <td >Total Capital Investment:</td>
                <td v-if="getCapitalInvestmentSum()"><b class="font-yellow">€
                  <i-count-up
                          :start="0"
                          :endVal="getCapitalInvestmentSum()"
                          :duration="0.5"
                          :callback="callback"
                  ></i-count-up>
                </b></td>
                <td v-else style="text-align: right"><b class="font-yellow">€ 0</b></td>
              </table>
            </div>
          </div>
        </div>
        <div v-if="getOpportunities()  && getLoader() == false" class="row plain-element">
          <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
            <h6> {{ keywordFilteredList.length }} Results</h6>
          </div>
          <div class="col col-sm-6 col-md-6 col-lg-6 plain-element">
          </div>
          <div v-if="getResultCount() && keywordFilteredList.length > 0"
               class="col col-sm-3 col-md-3 col-lg-3 col-results plain-element text-end">
            <a target="_blank" v-if="site=='' && seu==''" href="/api/opportunities-download/"
               class="btn btn-report">
              <i class="fas fa-file-download"></i>&nbsp; Download
            </a>
            <a target="_blank" v-else-if="site!='' && seu==''" :href="'/api/site-search-opportunities-download/' + site + '/'"
               class="btn btn-report">
              <i class="fas fa-file-download"></i>&nbsp; Download
            </a>
            <a target="_blank" v-else-if="site!='' && seu!=''"
               :href="'/api/seu-search-opportunities-download/' + site + '/' + seu + '/'"
               class="btn btn-report">
              <i class="fas fa-file-download"></i>&nbsp; Download
            </a>
          </div>
        </div>
        <p class="w-100"></p>
        <div id="parent">
          <div id="child">
            <table v-if="getOpportunities() && getLoader() == false" name="text" id="searchTable">
              <thead v-if="keywordFilteredList.length < 200 && keywordFilteredList.length > 0">
              <tr>
                <th  class="text-start" @click="sort(0)">Site Code
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th  class="text-start" @click="sort(1)">SEU
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th class="text-center th-description">Description</th>
                <th @click="sort(3)" class="text-start">Created
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(4)" class="text-end">Savings(€)
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(5)" class="text-end">Cap. Inv.
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(6)" class="text-end">CO2
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(7)" class="text-end">MWHE
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(8)" class="text-end">MWHT
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortInt(9)" class="text-end">Payback
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>

              </tr>
              </thead>
              <thead v-else-if="keywordFilteredList.length > 200">
              <tr>
               <th class="text-start">Site Code</th>
                <th class="text-start">SEU</th>
                <th class="text-center th-description">Description</th>
                <th class="text-start">Created</th>
                <th class="text-end">Savings(€)</th>
                <th class="text-end">Cap. Inv.</th>
                <th class="text-end">CO2</th>
                <th class="text-end">MWHE</th>
                <th class="text-end">MWHT</th>
                <th class="text-end">Payback</th>

              </tr>
              </thead>
              <tbody>
                <tr v-for="opportunity in keywordFilteredList" :key="opportunity.id" @click="goToOpportunityView(opportunity.id.toString())">
                  <td class="text-start field-number">{{opportunity.site_code__site_code}}</td>
                  <td class="text-start field-number">{{opportunity.seu__seu}}</td>
                  <td class="text-start field-description">{{opportunity.description | truncatechars(240)}}</td>
                  <td class="text-start field-number">{{opportunity.date}}</td>
                  <td class="text-end field-number">{{opportunity.savings_eur.toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.capital_investment_eur.toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.co2_tonnes.toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.mwhe.toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.mwht.toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.payback_yrs}}</td>

                </tr>
              </tbody>
            </table>
            <div v-if="getOpportunities().length > 0" class="border-line"></div>
          </div>
        </div>

        <div v-if="resultCount && keywordFilteredList.length > 0" class="row plain-element">
          <button v-show="next" @click="submitQuery" class="btn btn-loading">
            Load More
          </button>
        </div>
        <div v-if="resultCount && keywordFilteredList.length == 0" class="row plain-element">
          <h6>No results match search criteria</h6>
        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import { sortTable, sortTableInt } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";
import ICountUp from 'vue-countup-v2';
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "OpportunityStandardSearch",
  components: {
      NoPermissionComponent,
      ICountUp,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "opportunities",
      siteList: null,
      searchQuery: null,
      site: "",
      seu: "",
      savingsSum: null,
      co2TonnesSum: null,
      capitalInvestmentSum: null,
    }
  },

  methods: {
    ...mapGetters(["getFormError","getNext","getFormError", "getResultCount", "getLoader",
                  "getSavingsSum", "getCo2TonnesSum", "getCapitalInvestmentSum",
                  "getOpportunities","getUsername", "isLoggedIn", "getNestedSitesAndSeu" ]),
    ...mapActions(["fetchOpportunitiesQueryset", "fetchOpportunityData", "performUpdateCalculations",
                    "clearOpportunityList", "performSetFormError",
                    "fetchAllOpportunities","fetchNestedSitesAndSeu"]),
    getFullOpportunityList() {
      this.fetchAllOpportunities();
    },
    clearSeu() {
        if (this.site == "") {
          this.seu = ""
          this.search = ""
        } else {
          this.seu = ""
          this.search = ""
        }
        this.updateCalculations()

    },
    clearSearch() {
       this.search = "";
       this.updateCalculations();
    },
    updateCalculations() {
      this.savingsSum = (this.keywordFilteredList.map(a => a.savings_eur)).reduce((a, b) => a + b, 0);
      this.co2TonnesSum = (this.keywordFilteredList.map(a => a.co2_tonnes)).reduce((a, b) => a + b, 0);
      this.capitalInvestmentSum = (this.keywordFilteredList.map(a => a.capital_investment_eur)).reduce((a, b) => a + b, 0);
      this.performUpdateCalculations({"savingsSum": this.savingsSum, "co2TonnesSum" :this.co2TonnesSum, "capitalInvestmentSum": this.capitalInvestmentSum})
    },
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },
    goToOpportunityView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      }
    },
//    TABLE_SORTING FUNCTIONS
    sort(n) {
      sortTable(n)
    },
    sortInt(n) {
      sortTableInt(n)
    },
  },
  computed: {
    filteredList() {
        if (this.seu != ""  && this.site != "" ) {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() == this.site.toLowerCase() &&
                      opportunity.seu__seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.seu == "" && this.site == "") {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase().includes("")
            })
        } else if (this.seu == "" && this.site != "" ) {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() == this.site.toLowerCase()
            })
        } else {
          return this.getOpportunities()
        }
    },
    keywordFilteredList() {
      return this.filteredList.filter(opportunity => {
        return opportunity.site_code__site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.seu__seu.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.description.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {
    this.fetchNestedSitesAndSeu();
    this.performSetFormError(null);
    this.getFullOpportunityList();
    document.title = "Opportunity Search";
    window.scrollTo(0,0);
  },
  mounted() {

  },
  beforeCreated() {

  }
}
</script>
