<template>
  <div class="row-tabs plain-element text-center">
    <div v-for="site in sitesArray" class="btn-tab" :key="site" @click="activate(site)">
      <div v-if="site == getActiveSite()" class="tab-active">{{ site }}</div>
      <div v-else class="tab">{{ site }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RowTabsComponent",
  props: {
    sitesArray: { type: Array, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getActiveSite"]),
    ...mapActions(["activateSite"]),

    activate(site) {
        this.activateSite(site);
    },
  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>