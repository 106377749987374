import Vuex from 'vuex';
import Vue from 'vue';
import actions from './modules/actions';
import capacity from './modules/capacity';
import charts from './modules/charts';
import common from './modules/common';
import company from './modules/company';
import dashboards from './modules/dashboards';
import enpi from './modules/enpi';
import energy_eye from './modules/energy_eye';
import metrics from './modules/metrics';
import opportunities from './modules/opportunities';
import pdf_reports from './modules/pdf_reports';
import plans from './modules/plans';
import powerbi from './modules/powerbi';

import user from './modules/user';

// Connect Vue with Vuex
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    actions,
    capacity,
    charts,
    common,
    company,
    dashboards,
    energy_eye,
    enpi,
    metrics,
    opportunities,
    pdf_reports,
    plans,
    powerbi,
    user,
  }
});