import api from "../../api/api.js";



const state = {
  nestedRatedCapacities: null,
  capacityTableData: null,
  capacityTankTable: null,
  capacityChartData: {},
  capacityTankData: null,
  capacityChartDataset: {},
  capacityUnits: null,
  currentMax: null,
};


const getters = {
  getNestedRatedCapacities: state => state.nestedRatedCapacities,
  getCapacityTableData: state => state.capacityTableData,
  getCapacityTankTable: state => state.capacityTankTable,
  getCapacityChartData: state => state.capacityChartData,
  getCapacityTankData: state => state.capacityTankData,
  getCapacityChartDataset: state => state.capacityChartDataset,
  getCapacityUnits: state => state.capacityUnits,
  getCurrentMax: state => state.currentMax,
};


const actions = {
  async fetchNestedRatedCapacities({commit}) {
    const response = await api.nestedRatedCapacities();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedRatedCapacities", response.queryset);
    }
  },
  async performGetCapacity({commit, dispatch}, payload) {
    commit("setLoader", true);
    const response = await api.getCapacity(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
      commit("setLoader", false);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
        commit("setLoader", false);
      } else {
        commit("setCapacityTableData", response.table_data);
        commit("setCapacityTankTable", response.capacity_tank_data);
        commit("setCapacityUnits", response.capacity_units);
        commit("setCurrentMax", response.current_max);
        commit("setCapacityChartDataset", response);
        dispatch ("fillCapacityChart", response);
        commit("setLoader", false);
      }
    }
  },
  fillCapacityChart({ commit }, dataset) {
    const dataLabelsCapacityChart = Object.keys(dataset.capacity_chart_dict);
    const dataValuesCapacityChart = Object.values(dataset.capacity_chart_dict);
    var splitDateArray = dataLabelsCapacityChart.map(function(v){
       return v.split(" - ").shift();
    });
    var chartData = {};
    if (Object.keys(dataset.capacity_tank_dict).length > 0) {
        console.log("Tank Data Present");
        commit("setCapacityTankData", dataset.capacity_tank_dict);
        const dataValuesTankChart = Object.values(dataset.capacity_tank_dict);
        chartData = {
          labels: splitDateArray,
          datasets: [
            {
              label: dataset.meter_tag,
              data: dataValuesCapacityChart,
              borderColor: '#1f77b4',
              backgroundColor: 'rgba(142, 186, 217, 0.6)',
              fill: true,
              pointRadius: 1.5,
              yAxisID: 'y',
            },
            {
              label: "Fill Level(%)",
              data: dataValuesTankChart,
              borderColor: '#15b8c9',
              backgroundColor: '#15b8c9',
              pointRadius: 1.25,
              borderWidth: 1,
              yAxisID: 'y1',
            },

            {
              label: "Current Max",
              borderColor: '#d62728',
              backgroundColor: '#d62728',
              data: new Array(dataLabelsCapacityChart.length).fill(dataset.current_max),
              borderDash: [10,5],
              pointRadius: 0,
              borderWidth: 2,
              yAxisID: 'y',
            },
            {
              label: "Rated Capacity",
              data: new Array(dataLabelsCapacityChart.length).fill(dataset.rated_capacity),
              borderColor: '#2ca02c',
              backgroundColor: '#2ca02c',
              borderDash: [10,5],
              pointRadius: 0,
              borderWidth: 2,
              yAxisID: 'y',
            },
          ]
        };
    } else {
        chartData = {
          labels: splitDateArray,
          datasets: [
            {
              label: dataset.meter_tag,
              data: dataValuesCapacityChart,
              borderColor: '#1f77b4',
              backgroundColor: 'rgba(142, 186, 217, 0.6)',
              fill: true,
              pointRadius: 1.5,
            },
            {
              label: "Current Max",
              borderColor: '#d62728',
              backgroundColor: '#d62728',
              data: new Array(dataLabelsCapacityChart.length).fill(dataset.current_max),
              borderDash: [10,5],
              pointRadius: 0,
              borderWidth: 2,
            },
            {
              label: "Rated Capacity",
              data: new Array(dataLabelsCapacityChart.length).fill(dataset.rated_capacity),
              borderColor: '#2ca02c',
              backgroundColor: '#2ca02c',
              borderDash: [10,5],
              pointRadius: 0,
              borderWidth: 2,
            },
          ]
        };


    }
    commit("setCapacityChartData", chartData)

  },
  updateCapacityChart({ commit }, payload) {
    const dataLabelsCapacityChart = Object.keys(payload.dataset.capacity_chart_dict);
    const dataValuesCapacityChart = Object.values(payload.dataset.capacity_chart_dict);
    var splitDateArray = dataLabelsCapacityChart.map(function(v){
       return v.split(" - ").shift();
    });
    var chartData = {};
    if (parseInt(payload.change) != 0) {
        var dataValuesCapacityChange = null
        if (payload.change_type == "%") {
            dataValuesCapacityChange = dataValuesCapacityChart.map(x =>  x * parseInt(payload.change_factor) / 100 + x);
        } else {
            dataValuesCapacityChange = dataValuesCapacityChart.map(x => parseInt(payload.change_factor) + x);
        }
        if (Object.keys(payload.dataset.capacity_tank_dict).length > 0) {
            commit("setCapacityTankData", payload.dataset.capacity_tank_dict);
            const dataValuesTankChart = Object.values(payload.dataset.capacity_tank_dict);
            chartData = {
            labels: splitDateArray,
            datasets: [

                {
                  label: payload.dataset.meter_tag,
                  data: dataValuesCapacityChart,
                  borderColor: '#1f77b4',
                  backgroundColor: 'rgba(142, 186, 217, 0.6)',
                  fill: true,
                  pointRadius: 1.5,
                },
                {
                  label: "Capacity Change",
                  data: dataValuesCapacityChange,
                  borderColor: 'rgba(255, 127, 15, 0.2)',
                  backgroundColor: 'rgba(255, 127, 15, 0.2)',
                  fill: true,
                  pointRadius: 1,
                },
                {
                  label: "Fill Level(%)",
                  data: dataValuesTankChart,
                  borderColor: '#15b8c9',
                  backgroundColor: '#15b8c9',
                  pointRadius: 1.25,
                  borderWidth: 1,
                  yAxisID: 'y1',
                },

                {
                  label: "Current Max",
                  borderColor: '#d62728',
                  backgroundColor: '#d62728',
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.current_max),
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "Rated Capacity",
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.rated_capacity),
                  borderColor: '#2ca02c',
                  backgroundColor: '#2ca02c',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "New Max",
                  data: new Array(dataLabelsCapacityChart.length).fill(parseInt(payload.change)),
                  borderColor: '#ff7f0f',
                  backgroundColor: '#ff7f0f',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
          ]
        };

        } else {
            chartData = {
            labels: splitDateArray,
            datasets: [
                 {
                  label: payload.dataset.meter_tag,
                  data: dataValuesCapacityChart,
                  borderColor: '#1f77b4',
                  backgroundColor: 'rgba(142, 186, 217, 0.6)',
                  fill: true,
                  pointRadius: 1.5,
                },
                {
                  label: "Capacity Change",
                  data: dataValuesCapacityChange,
                  borderColor: 'rgba(255, 127, 15, 0.2)',
                  backgroundColor: 'rgba(255, 127, 15, 0.2)',
                  fill: true,
                  pointRadius: 1,
                },
                {
                  label: "Current Max",
                  borderColor: '#d62728',
                  backgroundColor: '#d62728',
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.current_max),
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "Rated Capacity",
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.rated_capacity),
                  borderColor: '#2ca02c',
                  backgroundColor: '#2ca02c',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "Capacity Change",
                  data: new Array(dataLabelsCapacityChart.length).fill(parseInt(payload.change)),
                  borderColor: '#ff7f0f',
                  backgroundColor: '#ff7f0f',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
          ]
        };

        }
    } else {
        if (Object.keys(payload.dataset.capacity_tank_dict).length > 0) {
            commit("setCapacityTankData", payload.dataset.capacity_tank_dict);
            const dataValuesTankChart = Object.values(payload.dataset.capacity_tank_dict);
            chartData = {
            labels: dataLabelsCapacityChart,
            datasets: [
                 {
                  label: payload.dataset.meter_tag,
                  data: dataValuesCapacityChart,
                  borderColor: '#1f77b4',
                  backgroundColor: 'rgba(142, 186, 217, 0.6)',
                  fill: true,
                  pointRadius: 1.5,
                },
                {
                  label: "Fill Level(%)",
                  data: dataValuesTankChart,
                  borderColor: '#15b8c9',
                  backgroundColor: '#15b8c9',
                  pointRadius: 1.25,
                  borderWidth: 1,
                  yAxisID: 'y1',
                 },
                {
                  label: "Current Max",
                  borderColor: '#d62728',
                  backgroundColor: '#d62728',
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.current_max),
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "Rated Capacity",
                  data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.rated_capacity),
                  borderColor: '#2ca02c',
                  backgroundColor: '#2ca02c',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
                {
                  label: "Capacity Change",
                  data: new Array(dataLabelsCapacityChart.length).fill(parseInt(payload.change)),
                  borderColor: '#ff7f0f',
                  backgroundColor: '#ff7f0f',
                  borderDash: [10,5],
                  pointRadius: 0,
                  borderWidth: 2,
                },
          ]
        };

        } else {
            chartData = {
                  labels: dataLabelsCapacityChart,
                  datasets: [
                     {
                      label: payload.dataset.meter_tag,
                      data: dataValuesCapacityChart,
                      borderColor: '#1f77b4',
                      backgroundColor: 'rgba(142, 186, 217, 0.6)',
                      fill: true,
                      pointRadius: 1.5,
                    },
                    {
                      label: "Current Max",
                      borderColor: '#d62728',
                      backgroundColor: '#d62728',
                      data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.current_max),
                      borderDash: [10,5],
                      pointRadius: 0,
                      borderWidth: 2,
                    },
                    {
                      label: "Capacity Change",
                      data: new Array(dataLabelsCapacityChart.length).fill(payload.dataset.rated_capacity),
                      borderColor: '#ff7f0f',
                      backgroundColor: '#ff7f0f',
                      borderDash: [10,5],
                      pointRadius: 0,
                      borderWidth: 2,
                    },
                    {
                      label: "Capacity Change",
                      data: new Array(dataLabelsCapacityChart.length).fill(parseInt(payload.change)),
                      borderColor: '#ff7f0f',
                      backgroundColor: '#ff7f0f',
                      borderDash: [10,5],
                      pointRadius: 0,
                      borderWidth: 2,
                    },

                  ]
            };


        }


    }



    commit("setCapacityChartData", chartData)
  },

};


const mutations = {

  setNestedRatedCapacities: (state, nestedRatedCapacities) => {
    state.nestedRatedCapacities = nestedRatedCapacities;
  },
  setCapacityTableData: (state, capacityTableData) => {
    state.capacityTableData = capacityTableData;
  },
  setCapacityTankTable: (state, capacityTankTable) => {
    state.capacityTankTable = capacityTankTable;
  },
  setCapacityChartData: (state, capacityChartData) => {
    state.capacityChartData = capacityChartData;
  },
  setCapacityTankData: (state, capacityTankData) => {
    state.capacityTankData = capacityTankData;
  },
  setCapacityChartDataset: (state, capacityChartDataset) => {
    state.capacityChartDataset = capacityChartDataset;
  },
  setCapacityUnits: (state, capacityUnits) => {
    state.capacityUnits = capacityUnits;
  },
  setCurrentMax: (state, currentMax) => {
    state.currentMax = currentMax;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
}