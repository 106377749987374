import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data(){
    return {
      options: {
        plugins : {
            legend: {
                position: "top",
                padding: 25,
                labels: {
                    font: {

                    }

                }
            },
            datalabels: { // Disable datalabels for this chart
                display: false
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
              position: 'left',
              grid: {
                display: true,
                color: "#e4e6e8",

              },
              ticks: {
                maxTicksLimit: 10,
                font: {
                  size: 10
                },
              }
            },
            x: {
              grid: {
                display: false
              },
              ticks: {
                maxTicksLimit: 8,
                font: {
                  size: 10
                },
                maxRotation: 0,
                minRotation: 0,
              },

            },

        }

      }
    }
  },
  mounted () {
    this.renderChart(
      this.chartData,
      this.options
      )
  }
}
