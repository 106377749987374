import api from "../../api/api.js"

const state = {
  dashboard: null,
  activeSite: null,
};


const getters = {
  getDashboard: state => state.dashboard,
  getActiveSite: state => state.activeSite,
};


const actions = {
  async fetchDashboard({ commit }) {
    const response = await api.dashboard();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      commit('setDashboard', response);
      commit("setActiveSite", "Overall");
    }
  },
  activateSite({commit}, site) {
    commit("setActiveSite", site);

  },
};

const mutations = {
  setDashboard: (state, dashboard) => {
    state.dashboard = dashboard
  },
  setActiveSite: (state, activeSite) => {
    state.activeSite = activeSite
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}