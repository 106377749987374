import api from "../../api/api.js"

const state = {
  resultCount: null,
  currentSearch: null,
  loader: false,
  next: null,
  formError: null,
  sitesAndSeu: null,
  sitesAndSeuAndOpp: null,
  siteSeu: null,
  opportunitiesSites: null,
  nestedSitesAndSeu: null,
  actionTypes: null,
  eventTypes: null,
  costIntervals: null,
  opportunityStatusList: null,
  iso_chapters: null,
  nestedSitesAndPdfReportProjects: null,
  sites: null,

};


const getters = {
  getResultCount: state => state.resultCount,
  getCurrentSearch: state => state.currentSearch,
  getLoader: state => state.loader,
  getNext: state => state.next,
  getFormError: state => state.formError,
  getSiteSeu: state => state.siteSeu,
  getSitesAndSeu: state => state.sitesAndSeu,
  getSitesAndSeuAndOpp: state => state.sitesAndSeuAndOpp,
  getOpportunitiesSites: state => state.opportunitiesSites,

  getNestedSitesAndSeu: state => state.nestedSitesAndSeu,

  getActionTypes: state => state.actionTypes,
  getEventTypes: state => state.eventTypes,
  getCostIntervals: state => state.costIntervals,
  getOpportunityStatusList: state => state.opportunityStatusList,

  getSites: state => state.sites,
  getIsoChapters: state => state.iso_chapters,
  getNestedSitesAndPdfReportProjects: state => state.nestedSitesAndPdfReportProjects,


};


const actions = {
  async fetchSites({commit}) {
    const response = await api.getAllSites();
    commit("setSites", response.results);
  },
  async fetchIsoChapters({commit}) {
    const response = {
    "count": 29,
    "next": null,
    "previous": null,
    "results": [
                    {
                        "id": 1,
                        "chapter": "4.0",
                        "title": "Context of the organisation"
                    },
                    {
                        "id": 2,
                        "chapter": "4.1",
                        "title": "Understanding the Organization and its context"
                    },
                    {
                        "id": 3,
                        "chapter": "4.2",
                        "title": "Understanding the Needs & Expectations of Interested Parties"
                    },
                    {
                        "id": 4,
                        "chapter": "4.3",
                        "title": "Scope of the EnMS"
                    },
                    {
                        "id": 5,
                        "chapter": "4.4",
                        "title": "Energy Management System"
                    },
                    {
                        "id": 6,
                        "chapter": "5.0",
                        "title": "Leadership"
                    },
                    {
                        "id": 7,
                        "chapter": "5.2",
                        "title": "Energy Policy"
                    },
                    {
                        "id": 8,
                        "chapter": "6.0",
                        "title": "Planning"
                    },
                    {
                        "id": 9,
                        "chapter": "6.2",
                        "title": "Objectives, energy targets and planning to achieve them"
                    },
                    {
                        "id": 10,
                        "chapter": "6.3",
                        "title": "Energy Review"
                    },
                    {
                        "id": 11,
                        "chapter": "6.4",
                        "title": "Energy Performance Indicators"
                    },
                    {
                        "id": 12,
                        "chapter": "6.6",
                        "title": "Planning for the collection of energy data"
                    },
                    {
                        "id": 13,
                        "chapter": "7.0",
                        "title": "Support"
                    },
                    {
                        "id": 14,
                        "chapter": "7.1",
                        "title": "Resources"
                    },
                    {
                        "id": 15,
                        "chapter": "7.2",
                        "title": "Competence"
                    },
                    {
                        "id": 16,
                        "chapter": "7.3",
                        "title": "Awareness"
                    },
                    {
                        "id": 17,
                        "chapter": "7.4",
                        "title": "Communication"
                    },
                    {
                        "id": 18,
                        "chapter": "7.4.1",
                        "title": "Internal Communications"
                    },
                    {
                        "id": 19,
                        "chapter": "7.5",
                        "title": "Documented Information"
                    },
                    {
                        "id": 20,
                        "chapter": "8.0",
                        "title": "Operation"
                    },
                    {
                        "id": 21,
                        "chapter": "8.1",
                        "title": "Operational Planning & Control"
                    },
                    {
                        "id": 22,
                        "chapter": "8.2",
                        "title": "Design"
                    },
                    {
                        "id": 23,
                        "chapter": "8.3",
                        "title": "Procurement"
                    },
                    {
                        "id": 24,
                        "chapter": "9.0",
                        "title": "Performance Evaluations"
                    },
                    {
                        "id": 25,
                        "chapter": "9.1.1",
                        "title": "Monitoring"
                    },
                    {
                        "id": 26,
                        "chapter": "9.2",
                        "title": "Internal Audit"
                    },
                    {
                        "id": 27,
                        "chapter": "9.3",
                        "title": "Management Review"
                    },
                    {
                        "id": 28,
                        "chapter": "10.0",
                        "title": "Improvement"
                    },
                    {
                        "id": 29,
                        "chapter": "-",
                        "title": "-"
                    }
                ]
            }

    commit("setIsoChapters", response.results);
  },
  async fetchNestedSitesAndPdfReportProjects({commit}) {
    const response = {
                      "queryset":
                        {
                          "DEMO1": [
                              "DEMO1000"
                          ],
                          "DEMO2": [
                              "DEMO2000"
                          ],
                          "DEMO3": [
                              "DEMO3000"
                          ],
                          "DEMO4": [
                              "DEMO4000"
                          ]
                        }
                      }
    commit("setNestedSitesAndPdfReportProjects", response.queryset);

  },
  async fetchSitesAndSeu({commit}) {
    const response = await api.sitesAndSeu();
    commit("setSitesAndSeu", response);
  },
  async fetchSitesAndSeuAndOpp({commit}) {
    const response = await api.sitesAndSeuAndOpp();
    commit("setSitesAndSeuAndOpp", response);
  },
  async fetchOpportunitiesSites({commit}) {
    const response = await api.opportunitiesSites();
    commit("setOpportunitiesSites", response);
  },
  async fetchNestedSitesAndSeu({commit}) {
    const response = await api.nestedSitesAndSeu();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedSitesAndSeu", response.queryset);
    }
  },
  async fetchSiteSeu({commit}, payload) {
    const response = await api.siteSeuList(payload.id);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setSiteSeu", response.seu);
    }
  },

  async fetchActionTypes({commit}) {
    const response = await api.actionTypes();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setActionTypes", response.results);
    }
  },
  async fetchEventTypes({commit}) {
    const response = await api.eventTypes();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setEventTypes", response.results);
    }
  },
  async fetchCostIntervals({commit}) {
    const response = await api.costIntervals();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setCostIntervals", response.results);
    }
  },
  async fetchOpportunityStatusList({commit}) {
    const response = await api.opportunityStatusList();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setOpportunityStatusList", response.results);
    }
  },
  performSetFormError({commit}, error) {
    commit("setFormError", error);
  },
};


const mutations = {
  setResultCount:  (state, resultCount) => {
    state.resultCount = resultCount;
  },
  setLoader: (state, loader) => {
    state.loader = loader;
  },
  setNext: (state, next) => {
    state.next = next
  },
  setCurrentSearch: (state, currentSearch) => {
    state.currentSearch = currentSearch
  },
  setFormError: (state, formError) => {
    state.formError = formError
  },
  setSitesAndSeu: (state, sitesAndSeu) => {
    state.sitesAndSeu = sitesAndSeu
  },
  setSitesAndSeuAndOpp: (state, sitesAndSeuAndOpp) => {
    state.sitesAndSeuAndOpp = sitesAndSeuAndOpp
  },
  setNestedSitesAndSeu: (state, nestedSitesAndSeu) => {
    state.nestedSitesAndSeu = nestedSitesAndSeu
  },
  setSiteSeu: (state, siteSeu) => {
    state.siteSeu = siteSeu
  },
  setOpportunitiesSites: (state, opportunitiesSites) => {
    state.opportunitiesSites = opportunitiesSites
  },

  setEventTypes: (state, eventTypes) => {
    state.eventTypes = eventTypes;
  },
  setActionTypes: (state, actionTypes) => {
    state.actionTypes = actionTypes;
  },
  setCostIntervals: (state, costIntervals) => {
    state.costIntervals = costIntervals;
  },
  setOpportunityStatusList: (state, opportunityStatusList) => {
    state.opportunityStatusList = opportunityStatusList;
  },
  setSites: (state, sites) => {
    state.sites = sites
  },
  setIsoChapters: (state, iso_chapters) => {
    state.iso_chapters = iso_chapters
  },
  setNestedSitesAndPdfReportProjects: (state, nestedSitesAndPdfReportProjects) => {
    state.nestedSitesAndPdfReportProjects = nestedSitesAndPdfReportProjects
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}