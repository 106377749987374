<template>

    <div v-if="latestActionArray[0]"

       class="card card-site-dashboard card-site-dashboard-3 clickable">
      <div class="row-card-site-dashboard-title">
        Latest Action
      </div>
      <div class="row-card-site-dashboard-date">
        {{latestActionArray[0]["created_date"]}}
      </div>
      <div class="row row-card-site-details-title">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 text-start">
          <div class="row-card-site-opportunity-details">
            SEU: <span
                  class="span-opportunity-detail">{{latestActionArray[0]["seu__seu"]}}</span>
          </div>
          <div class="row-card-site-opportunity-details">
            Cost: <span
                  class="span-opportunity-detail">{{latestActionArray[0]["cost"]}}€<span
                  v-if="latestActionArray[0]['cost_interval__cost_interval'] == 'Actual' | latestActionArray[0]['cost_interval__cost_interval'] == 'None'">(Total)</span><span
                  v-else>/{{latestActionArray[0]["cost_interval__cost_interval"]}}</span></span>
          </div>
        </div>
      </div>

      <div class="row row-card-site-details-description opportunity-description text-start">
        {{latestActionArray[0]["comment"] | truncatechars(84)}}
      </div>

    </div>


</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LatestActionCardComponent",
  props: {
    latestActionArray: { type: Array, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUserOverallView", "getActiveSite"]),
    ...mapActions([]),

    goToLatestAction(id) {
        window.open(`/action-details/${id}`, '_blank');
    },
  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>